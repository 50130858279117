import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { defaultInputClassName } from '@/configs/input.config';
import { cn } from '@/lib/utils';
import { checkIsMobileDevice } from '@/utils/device.utils';
import { Check, ChevronsUpDown, Search } from 'lucide-react';
import { ReactNode, useState } from 'react';
import InputLabel from './InputLabel';
import { Input } from './ui/input';

type Option = { value: string; label: string };

type ComboBoxResponsiveProps = {
  label?: ReactNode;
  placeholder?: string;
  title?: string;
  description?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (value: Option | null) => void;
  onBlur?: () => void;
  options?: Option[];
  errorMessage?: string;
};

export function ComboBoxResponsive({
  label,
  placeholder = 'Selecione...',
  title = 'Selecione',
  description = 'Selecione uma opção',
  required,
  disabled,
  onChange,
  value,
  errorMessage,
  options,
  onBlur,
}: ComboBoxResponsiveProps) {
  const isMobile = checkIsMobileDevice();
  const [open, setOpen] = useState(false);
  const id = `${Math.random() * 100}-${label}`;
  const Label = () =>
    label ? (
      <InputLabel htmlFor={id} required={required}>
        {label}
      </InputLabel>
    ) : (
      <></>
    );

  const selectedOptionLabel = options?.find(
    (option) => option.value == value,
  )?.label;
  if (!isMobile) {
    return (
      <div className="w-full">
        <Label />
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger
            id={id}
            asChild
            className="mx-auto mt-1 max-w-full justify-between"
            onBlur={onBlur}
            disabled={disabled}
          >
            <div className="relative">
              <button
                disabled={disabled}
                onBlur={onBlur}
                type="button"
                className={cn(
                  !selectedOptionLabel && 'text-muted-foreground',
                  defaultInputClassName(errorMessage),
                  'mt-0 select-none',
                )}
              >
                {selectedOptionLabel ? selectedOptionLabel : placeholder}
                <ChevronsUpDown className="absolute bottom-0 right-3 top-0 my-auto p-[0.2rem] text-muted-foreground" />
              </button>
            </div>
          </PopoverTrigger>
          <PopoverContent
            className="w-[50rem] p-0"
            align="center"
            side="bottom"
          >
            <OptionsList
              setOpen={setOpen}
              selectedOption={value}
              setSelectedOption={onChange}
              options={options}
            />
          </PopoverContent>
        </Popover>
        <span
          data-hidden={!errorMessage}
          className="text-sm font-medium text-pormade-red data-[hidden=true]:hidden"
        >
          {errorMessage}
        </span>
      </div>
    );
  }

  return (
    <div className="w-full">
      <Label />
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger
          id={id}
          asChild
          className="mx-auto mt-1 flex justify-between md:max-w-full"
          onBlur={onBlur}
          disabled={disabled}
        >
          <div className="relative">
            <button
              disabled={disabled}
              type="button"
              className={cn(
                defaultInputClassName(errorMessage),
                'mt-0 select-none',
              )}
            >
              {selectedOptionLabel ? selectedOptionLabel : placeholder}
              <ChevronsUpDown className="absolute bottom-0 right-3 top-0 my-auto p-[0.2rem]" />
            </button>
          </div>
        </DrawerTrigger>
        <DrawerContent>
          <div className="mb-2 mt-4">
            <DrawerTitle className="px-5">{title}</DrawerTitle>
            <DrawerDescription className="px-5">
              {description}
            </DrawerDescription>
          </div>
          <div className="mt-2 border-t">
            <OptionsList
              selectedOption={value}
              setOpen={setOpen}
              setSelectedOption={onChange}
              options={options}
            />
          </div>
        </DrawerContent>
      </Drawer>
      <span
        data-hidden={!errorMessage}
        className="text-sm font-medium text-pormade-red data-[hidden=true]:hidden"
      >
        {errorMessage}
      </span>
    </div>
  );
}

function OptionsList({
  setOpen,
  setSelectedOption,
  selectedOption,
  options,
}: {
  setOpen: (open: boolean) => void;
  setSelectedOption: (status: Option | null) => void;
  selectedOption?: string;
  options?: Option[];
}) {
  const [search, setSearch] = useState<string>();

  const filtredOptions = options?.filter((option) =>
    option.label.toLowerCase().includes(search?.toLowerCase() ?? ''),
  );

  return (
    <Command className="bg-background">
      <div className="mb-2 flex items-center border-b border-muted-foreground/50 bg-background px-3">
        <Search className="mr-2 size-5 shrink-0 opacity-50" />
        <Input
          placeholder="Buscar ..."
          className={
            'flex h-11 w-full border-none bg-background py-3 text-sm outline-none ring-0 placeholder:text-muted-foreground focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-background'
          }
          onChangeValue={setSearch}
        />
      </div>
      <CommandList className="custom-scrollbar bg-background">
        <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>
        <CommandGroup className="bg-background">
          {filtredOptions?.map((option) => (
            <CommandItem
              key={option.value}
              value={option.value}
              className="py-3"
              onSelect={(value) => {
                setSelectedOption(
                  filtredOptions.find((priority) => priority.value === value) ||
                    null,
                );
                setOpen(false);
              }}
            >
              <Check
                className={cn(
                  'mr-2 h-4 w-4',
                  selectedOption === option.value ? 'opacity-100' : 'opacity-0',
                )}
              />
              {option.label}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
