export const tabsNames: { [key: string]: string } = {
  novas_oportunidades: 'Novas oportunidades',
  localizados_sis_crm: 'SiS e CRM',
  localizados_sis: 'Somente no SiS',
  localizados_crm: 'Somente no CRM',
};

export const headersNames: { [key: string]: string } = {
  id_lead: 'ID do lead',
  tratado: 'Assumido',
  tratado_usuario: 'Responsável',
  usuario_registrador: 'Registrado por',
  data_coleta: 'Data de coleta',
  data_registro: 'Data de registro',
  nome: 'Nome',
  profissao: 'Profissão',
  interesse: 'Interesse',
  area_atuacao: 'Área de atuação',
  celular: 'Celular',
  telefone: 'Telefone',
  segmento_setor: 'Aba',
  email: 'Email',
  empresa: 'Empresa',
  ibge: 'Código IBGE',
  bairro: 'Bairro',
  endereco: 'Endereço',
  cidade: 'Cidade',
  cep: 'CEP',
  cnpj: 'CPF / CNPJ',
  data: 'Data',
  sis_nome: 'Nome',
  sis_pessoa_ativo: 'Pessoa Ativa',
  sis_cliente_ativo: 'Cliente Ativo',
  sis_telefone_cliente: 'Telefone (cliente)',
  sis_email_cliente: 'Email (cliente)',
  sis_email_parceiro: 'Email (parceiro)',
  sis_telefone_parceiro: 'Telefone (parceiro)',
  sis_parceiro_ativo: 'Parceiro Ativo',
  sis_patrocinador_ativo: 'Patrocinador Ativo',
  sis_patrocinador: 'Nome do patrocinador',
  sis_grupo_patrocinador: 'Grupo patrocinador',
  sis_cnpj_cpf: 'CPF / CNPJ',
  sis_data_cadastro: 'Data de cadastro',
  sis_numero_cupom: 'Número do cupom',
  telefone_cliente: 'Telefone do cliente',
  telefone_parceiro: 'Telefone do parceiro',
  email_cliente: 'Email do cliente',
  email_parceiro: 'Email do parceiro',
  crm_origem_id: 'ID da Origem',
  crm_origem_nome: 'Origem',
  crm_card: 'Card',
  crm_nome: 'Nome',
  crm_cnpj: 'CNPJ',
  crm_email_cliente: 'Email',
  crm_telefone_cliente: 'Telefone',
  crm_card_situacao: 'Situação',
  crm_usuario_criacao: 'Criador',
  crm_gestor: 'Gestor',
  crm_setor_criador: 'Setor criador',
  crm_funil: 'Funil',
  crm_etapa: 'Etapa',
  crm_origem_descricao: 'Origem',
  crm_origem_detalhe: 'Detalhes da origem',
  crm_parceiro_vinculado: 'Parceiro vinculado',
  crm_etiqueta: 'Etiqueta',
  obs_crm: 'Obs.',
  crm_data_cadastro: 'Data de cadastro',
  crm_data_alteracao: 'Data da última alteração',
  crm_data_perda: 'Data da perda',
  crm_motivo_perda: 'Motivo da perda',
  crm_data_ganho: 'Data de ganho',
  crm_data_entregue: 'Data de entrega',
  obs: 'Obs.',
  observacoes: 'Obs.',
};

export const cellsNames: { [key: string]: string } = {
  true: 'Sim',
  false: 'Não',
};

export const hiddenColumns = [
  'id_lead',
  'tratado',
  'segmento_setor',
  'crm_origem_id',
  'crm_origem_nome',
  'id_planilha_dados',
  'id_planilha',
];
