import { TRouteItem, TUserProfile } from '@/types';
import { RouteObject } from 'react-router-dom';

import {
  FilePlus,
  HomeIcon,
  PartyPopper,
  Users,
  UserSearch,
} from 'lucide-react';

import Events from '@/pages/events';
import Home from '@/pages/Home';
import Leads from '@/pages/leads';
import OriginLeads from '@/pages/leads/origin-leads';
import RegisterLead from '@/pages/leads/register-lead';
import NavigationRoute from '@/pages/NavigationRoute';
import ProtectedRoute from '@/pages/ProtectedRoute';
import ConfigSheet from '@/pages/sheets/ConfigureSheetHeaders';
import RegisterSheet from '@/pages/sheets/RegisterSheet';
import { Permission } from '@/types/permissions.types';

export const routes: TRouteItem[] = [
  { title: 'Inicio', icon: HomeIcon, href: '/', RouterElement: <Home /> },
  {
    title: 'Leads',
    icon: Users,
    items: [
      {
        title: 'Cadastro de planilha',
        href: '/cadastrar-planilha',
        icon: FilePlus,
        RouterElement: <RegisterSheet />,
        requiredPermissions: ['cadastrar_planilhas'],
      },
      {
        title: 'Leads cadastrados',
        href: '/leads',
        icon: UserSearch,
        RouterElement: <Leads />,
      },
    ],
  },
  {
    title: 'Feiras e Eventos',
    icon: PartyPopper,
    href: '/eventos',
    RouterElement: <Events />,
  },
];

const hiddenRoutes: TRouteItem[] = [
  {
    href: '/cadastrar-planilha/configuracao',
    RouterElement: (
      <NavigationRoute
        element={<ConfigSheet />}
        goBackTo={'/cadastrar-planilha'}
        route="configSheet"
      />
    ),
    requiredPermissions: ['cadastrar_planilhas'],
  },
  {
    href: '/leads/:id',
    RouterElement: <OriginLeads />,
  },
  {
    href: '/leads/cadastrar/:origemId',
    RouterElement: <RegisterLead />,
    requiredPermissions: ['cadastrar_leads'],
  },
];

const getRouterChildrens = () =>
  [...routes, ...hiddenRoutes].flatMap((content) => {
    if (content.href && content.RouterElement) {
      if (content.requiredPermissions) {
        return {
          path: content.href,
          element: (
            <ProtectedRoute
              requiredPermissions={content.requiredPermissions}
              redirectTo={content.redirectTo}
            >
              {content.RouterElement}
            </ProtectedRoute>
          ),
        };
      }

      return {
        path: content.href,
        element: content.RouterElement,
      };
    } else if (content.items) {
      return content.items
        .filter((item) => item.href && item.RouterElement)
        .map((item) =>
          item.requiredPermissions
            ? {
                path: item.href,
                element: (
                  <ProtectedRoute
                    requiredPermissions={item.requiredPermissions}
                    redirectTo={item.redirectTo}
                  >
                    {item.RouterElement}
                  </ProtectedRoute>
                ),
              }
            : {
                path: item.href,
                element: item.RouterElement,
              },
        );
    }
    return [];
  });

export const routerChildrens: RouteObject[] | undefined = getRouterChildrens();

export function getAuthorizedRoutes(
  userProfile?: TUserProfile,
): TRouteItem[] | undefined {
  if (!userProfile) return;

  const userPermissions = userProfile.permissions;

  if (userPermissions.includes('admin')) return routes;

  const hasRequiredPermissions = (requiredPermissions: Permission[]) =>
    requiredPermissions.every((permission) =>
      userPermissions.includes(permission),
    );

  return routes.reduce<TRouteItem[]>((acc, route) => {
    if (
      route.requiredPermissions &&
      !hasRequiredPermissions(route.requiredPermissions)
    ) {
      return acc;
    }

    const authorizedItems = route.items?.filter((item) =>
      item.requiredPermissions?.length
        ? item.requiredPermissions.every((permission) =>
            userPermissions.includes(permission),
          )
        : true,
    );

    if (authorizedItems?.length == 1) {
      const item = authorizedItems[0];
      acc.push({ ...route, ...item });
    } else {
      acc.push({ ...route, items: authorizedItems || route.items });
    }

    return acc;
  }, []);
}
